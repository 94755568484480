import { Session } from 'next-auth'
import { signOut } from 'next-auth/react'
import { baseApi } from 'src/config'
import { RequestInit } from './types'

export type ErrorType<T> = { [key in keyof T]: string } | { message?: string }

export const fetcher = async <T extends object>(props: {
  url: string
  session?: Session | null
  params?: RequestInit
  isContentTypeJson?: boolean
}) => {
  return new Promise(
    async (
      resolve: (value: T) => void,
      reject: (value: ErrorType<T>) => void,
    ) => {
      const { url, session = null, isContentTypeJson = true } = props
      const params = props.params ?? {}

      const headers: Record<string, string> =
        params && params.headers ? params.headers : {}

      if (session) {
        headers['authorization'] = `Bearer ${session?.user.accessToken}`
      }
      if (isContentTypeJson) {
        headers['Content-Type'] = 'application/json'
      }

      params.headers = headers
      // TODO: think about it (now we have api in frontend and in backend)

      const res = await fetch(`${baseApi}${url}`, {
        cache: 'no-cache',
        ...params,
      })

      if (typeof window !== 'undefined' && res.status === 401) {
        await signOut()
      }

      if ([404, 405].includes(res.status)) {
        return reject({ message: `${res.statusText} url: ${url}` })
      }

      let body: (T & { errors: ErrorType<T> }) | null = null

      try {
        body = (await res.json()).content

        if (res.ok) {
          return resolve(body as T)
        }
      } catch (e) {
        console.log('e', e)
      }

      reject({
        ...(body?.errors ?? { message: res.statusText }),
      })
    },
  )
}
