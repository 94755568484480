'use client'

import { HeartIcon, UserIcon } from '@heroicons/react/16/solid'
import { Book, BookStatistic, Genre } from '@prisma/client'
import clsx from 'clsx'
import Link from 'next/link'
import { memo } from 'react'
import { getBookImage, getBookTitle, isBlur } from 'src/app/lib/utils/book.util'
import { Icon } from 'src/components/common/Icon'
import { BookTypeEnum } from 'src/enums'
import { formatNumber, getBookUrl } from 'src/helpers/global.helper'
import { t } from 'src/helpers/translate.helper'
import { BookImage } from '../../BookImage'

export const BookCard = memo(function BookItem(props: {
  book: Book & { genres?: Genre[]; bookStatistic?: BookStatistic }
  position?: number
  isUserAuth: boolean
}) {
  const { bookStatistic } = props.book

  const getColor = () => {
    if (!bookStatistic || bookStatistic.reviews < 10) {
      return ''
    }

    const rate = +bookStatistic.rate

    if (rate >= 4.5) {
      return 'text-success'
    }

    if (rate < 4) {
      return 'text-danger'
    }

    return ''
  }

  return (
    <div
      key={props.book.id}
      className={clsx(
        'md:h-[180px] grid gap-x-4 md:gap-x-5 group relative h-[120px] overflow-hidden rounded-md dark:bg-black-700 bg-white',
        {
          'md:grid-cols-[120px_auto_30px] grid-cols-[80px_auto_25px]':
            props.position,
          'md:grid-cols-[120px_auto] grid-cols-[80px_auto]': !props.position,
        },
      )}
    >
      <BookImage
        fill
        isBlur={isBlur(props.isUserAuth, props.book)}
        commonClassName="h-[120px] md:h-[180px]"
        className="dark:bg-black-600 bg-gray-200"
        alt={getBookTitle(props.book)}
        src={getBookImage(props.book, 'imageMid')}
        isHoverEffect
      />

      <div
        className={clsx('items-center grid', {
          'mr-4 md:mr-5': !props.position,
        })}
      >
        <div>
          {!!props.book.type && (
            <p className="pt-1.5 md:pt-2 text-tiny leading-3 font-medium text-gray-primary">
              {t(BookTypeEnum.getLabels()[props.book.type])}
            </p>
          )}

          <Link
            href={getBookUrl(props.book.slug)}
            className={clsx(
              'mt-2 font-medium after:absolute after:inset-0 md:text-lg group-hover:text-primary line-clamp-2 hover leading-5 ',
            )}
          >
            {getBookTitle(props.book)}
          </Link>

          {props.book.genres && props.book.genres.length > 0 && (
            <p className="mt-1 md:mt-3 text-sm text-gray-primary line-clamp-1 md:line-clamp-2">
              {props.book.genres
                .slice(0, 3)
                .map((genre) => genre.title)
                .join(', ')}
            </p>
          )}

          {bookStatistic && (
            <div className="grid grid-flow-col text-gray-primary gap-x-4 mt-2 items-center justify-start">
              <div
                className={clsx(
                  'grid gap-x-1.5 grid-flow-col justify-start items-center',
                  getColor(),
                )}
              >
                <Icon name="star" className="w-4" />
                <span>{+bookStatistic.rate}</span>
                <span className="text-gray-primary">|</span>
                <UserIcon className="w-4 text-gray-primary" />
                <span className="text-gray-primary">
                  {formatNumber(bookStatistic.reviews)}
                </span>
              </div>

              <div className="grid gap-x-1.5 grid-flow-col justify-start items-center">
                <Icon name="bookmarks" className="w-4" />
                <span>{formatNumber(bookStatistic.bookmarks)}</span>
              </div>

              <div className="grid gap-x-1.5 grid-flow-col justify-start items-center">
                <HeartIcon name="" className="w-4" />
                <span>{formatNumber(bookStatistic.likes)}</span>
              </div>
            </div>
          )}
        </div>
      </div>

      {props.position && (
        <div className="mt-3 font-semibold md:text-lg text-gray-primary">
          {props.position}
        </div>
      )}
    </div>
  )
})
